<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <div class="d-flex pl-1 pr-2 align-center">
          <ion-buttons slot="start" @click="$emit('close-modal')">
            <ion-back-button
              color="primary"
              mode="ios"
              text=""
              default-href="/b2b/cart"
              :icon="chevronBackOutline"
            ></ion-back-button>
          </ion-buttons>
          <ion-label class="fw-600 pl-1 fs-3">{{ $t('checkout') }}</ion-label>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="isDisplay">
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card v-if="!isOnline" class="mx-0 mt-0 px-3 py-3 shadow border-rad-0">
        <ion-card-content class="pa-0">
          <div class="text-center">
            <ion-text class="fs-2">{{ $t('page_taking_longer') }}</ion-text>
            <div class="mt-4 d-flex column-center">
              <ion-label class="fw-600 mb-2">{{ $t('pull_to_refresh') }}</ion-label>
              <ion-icon color="primary fs-2rem" :icon="arrowDownOutline"></ion-icon>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
      <div class="bg-grey1 w-100" v-if="isOnline">
        <div class="pa-3 bg-white">
          <div class="d-flex border-bottom w-100 py-3">
            <ion-icon class="fs-4" :icon="businessOutline" />
            <ion-row class="adjust-top">
              <ion-col size="12">
                <span class="ml-2 fs-2 fw-500">{{
                  selectedCompany?.name ? selectedCompany?.name : selectedCompany?.branch
                }}</span>
              </ion-col>
              <ion-col size="12">
                <span class="ml-2 text-primary fs-2 fw-500">{{ selectedCompany?.account_number }}</span>
              </ion-col>
            </ion-row>
          </div>

          <!-- delivery_address -->
          <div class="py-3 border-bottom">
            <div class="d-flex mb-2">
              <!-- toggle button to let user select between delivery and self-pickup -->
              <div class="w-100">
                <ion-button
                  expand="full"
                  class="mr-0"
                  :color="isSelectDeliveryOverPickup ? 'primary' : 'light'"
                  @click="isSelectDeliveryOverPickup = true"
                  :disabled="
                    ![DELIVERY_TYPES.DELIVERY, DELIVERY_TYPES.DELIVERY_AND_PICKUP].includes(deliveryOptions)
                  "
                >
                  {{ $t('delivery') }}
                </ion-button>
              </div>
              <div class="w-100">
                <ion-button
                  expand="full"
                  class="ml-0"
                  :color="!isSelectDeliveryOverPickup ? 'primary' : 'light'"
                  @click="isSelectDeliveryOverPickup = false"
                  :disabled="
                    ![DELIVERY_TYPES.PICKUP, DELIVERY_TYPES.DELIVERY_AND_PICKUP].includes(deliveryOptions)
                  "
                >
                  {{ $t('self_pickup') }}
                </ion-button>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div>
                <ion-label color="grey" class="text-uppercase">
                  {{ isSelectDeliveryOverPickup ? $t('delivery_address') : $t('self_pickup_address') }}
                </ion-label>
              </div>
              <div
                v-if="
                  isSelectDeliveryOverPickup
                    ? list.length <= 1 || isPickingDeliveryAndBilling.isSelectDelivery
                    : false
                "
                @click="openModalAddress(TYPE_ADDRESS.DELIVERY)"
              >
                <ion-label color="primary" class="fw-500"> {{ $t('change') }}</ion-label>
              </div>
            </div>
            <div v-if="list.length <= 1 || isPickingDeliveryAndBilling.isSelectDelivery" class="mt-3">
              {{ addressDefault(selectedDeliveryAddress) }}
            </div>
            <ion-button
              v-if="list.length > 1 && !isPickingDeliveryAndBilling.isSelectDelivery"
              @click="openModalAddress(TYPE_ADDRESS.DELIVERY)"
              expand="block"
              fill="outline"
              >{{ $t('select_address') }}</ion-button
            >
          </div>

          <!-- billing_address -->
          <div class="py-3 border-bottom">
            <div class="d-flex justify-space-between">
              <div>
                <ion-label color="grey" class="text-uppercase">{{ $t('billing_address') }}</ion-label>
              </div>
              <div
                v-if="
                  isSelectDeliveryOverPickup
                    ? isPreSelectedAddress || isPickingDeliveryAndBilling.isSelectBilling
                    : false
                "
                @click="openModalAddress(TYPE_ADDRESS.BILLING)"
              >
                <ion-label color="primary" class="fw-500"> {{ $t('change') }}</ion-label>
              </div>
            </div>
            <div
              v-if="
                isSelectDeliveryOverPickup
                  ? isPreSelectedAddress || isPickingDeliveryAndBilling.isSelectBilling
                  : true
              "
              class="mt-3"
            >
              {{ addressDefault(selectedBillingAddress) }}
            </div>
            <ion-button
              v-if="
                isMustSelectAddress &&
                !isPickingDeliveryAndBilling.isSelectBilling &&
                isSelectDeliveryOverPickup
              "
              @click="openModalAddress(TYPE_ADDRESS.BILLING)"
              expand="block"
              fill="outline"
              >{{ $t('select_address') }}</ion-button
            >
          </div>
        </div>

        <!-- tenant -->
        <div
          class="mt-3 bg-white pa-3"
          v-for="(tenantItems, index) in groupedTenant"
          :key="index + uniqueId()"
        >
          <!-- tenant name -->
          <div class="d-flex align-center pb-2 border-bottom">
            <ion-icon :icon="storefrontOutline" class="fs-4"></ion-icon>
            <ion-label class="ml-2 text-ellipsis">{{ tenantItems[0].tenant_name }}</ion-label>
          </div>

          <!-- delivery time -->
          <div class="py-3">
            <div class="d-flex justify-space-between">
              <div>
                <ion-label color="grey">
                  {{ isSelectDeliveryOverPickup ? $t('delivery_time') : $t('self_pickup_time') }}
                </ion-label>
              </div>
              <div @click="chooseTime(index)">
                <ion-label color="primary" class="fw-500">
                  {{ isSelectDeliveryOverPickup ? $t('set_time') : $t('set_time_self_pickup') }}
                </ion-label>
              </div>
            </div>
            <div class="mt-3" v-if="deliveryDateNearest !== ''">
              {{ displayDeliveryDateNearest }}
              {{ data.cartItems[index].deliveryTime || '' }}
            </div>
            <div class="mt-3">
              {{ isShowNextDay ? '' : deliveryDateNearest ? $t('next_day') : $t('no_delivery_date') }}
            </div>
          </div>

          <!-- po number -->
          <ion-item class="fs-2 mb-3" v-if="!isB2cCustomer">
            <ion-label position="floating"> {{ $t('po_number') }} ({{ $t('optional') }})</ion-label>
            <ion-input tabindex="-1" v-model="data.cartItems[index].poNumber"></ion-input>
          </ion-item>

          <!-- product items -->
          <div class="border-bottom mb-4">
            <product-item-checkout
              v-for="item in tenantItems"
              :key="item.id"
              :item="item"
            ></product-item-checkout>
          </div>

          <!-- Payment method -->
          <div v-if="!isSubBuyer" class="payment-type mt-4" @click="openModalCreditCard(index)">
            <div class="credit-card">
              <div
                class="d-flex align-center"
                v-if="(!data.cartItems[index].paymentMethod && !user.is_b2c) || !canCheckoutByCreditCard"
              >
                <ion-icon class="fs-4 mr-2" :icon="cashOutline"></ion-icon>
                <ion-label>
                  {{ $t('credit_term') }}
                  ({{ customerView[index]?.customer_credit_term.displayName }})
                </ion-label>
              </div>

              <div
                class="d-flex align-center"
                v-else-if="data.cartItems[index].paymentMethod === PAYMENT_METHOD.PAYNOW"
              >
                <div class="mr-3 image-card d-flex">
                  <ion-img :src="getCardImage(PAYMENT_METHOD.PAYNOW)" />
                </div>
                <div>
                  <ion-label> {{ $t(PAYMENT_METHOD.PAYNOW) }} </ion-label>
                </div>
              </div>

              <div
                class="d-flex align-center"
                v-else-if="data.cartItems[index].paymentMethod === PAYMENT_METHOD.FLUID"
              >
                <div class="mr-3 image-card d-flex">
                  <ion-img :src="FLUID_ICON" />
                </div>
                <div>
                  <ion-label>
                    {{ $t('fluid') }}
                  </ion-label>
                </div>
              </div>

              <div class="d-flex align-center" v-else-if="listCards[index].__typename == 'NetsBankCard'">
                <div class="mr-3 image-card d-flex">
                  <ion-img :src="NetsCardImage" />
                </div>
                <div>
                  {{ $t('nets_bank_card') }} ({{
                    listCards[index].last4 || listCards[index].last_4_digits_fpan || $t('empty')
                  }})
                </div>
              </div>

              <div class="d-flex align-center" v-else>
                <div class="mr-3 image-card d-flex">
                  <ion-img :src="getCardImage(listCards[index].brand)" />
                </div>
                <div>
                  {{ $t('card') }} ({{
                    listCards[index].last4 || listCards[index].last_4_digits_fpan || $t('empty')
                  }})
                </div>
              </div>

              <div class="text-end d-flex align-center">
                <ion-icon class="fs-4" :icon="chevronForwardOutline"></ion-icon>
              </div>
            </div>
          </div>

          <!-- order remark -->
          <ion-item class="fs-2">
            <ion-label position="floating">
              {{ isSelectDeliveryOverPickup ? $t('note_to_driver') : $t('note_for_self_pickup') }} ({{
                $t('optional')
              }})
            </ion-label>
            <ion-input
              tabindex="-1"
              v-model="data.cartItems[index].description"
              :placeholder="
                isSelectDeliveryOverPickup
                  ? $t('add_delivery_instructions_or_directions')
                  : $t('add_self_pickup_instructions')
              "
            ></ion-input>
          </ion-item>
        </div>

        <!-- customer credit -->
        <div v-if="canCheckoutByCustomerCredit" class="pa-3 bg-white mt-3">
          <div class="d-flex align-center pb-2 border-bottom">
            <ion-icon :icon="cashOutline" class="fs-4"></ion-icon>
            <ion-label class="ml-2 text-ellipsis">{{ $t('apply_available_credit') }}</ion-label>
          </div>
          <div class="d-flex justify-space-between mt-3">
            <div>
              <ion-label color="dark">{{ $t('available_credits') }}</ion-label>
            </div>
            <div>
              <ion-label color="primary" class="fw-bold">
                {{ priceFormatter(currencySymbol, displayPrice(availableCustomerCredits)) }}
              </ion-label>
            </div>
          </div>

          <!-- customer credit -->
          <div class="d-flex justify-space-between align-center my-3">
            <div>
              <ion-label color="dark">{{ $t('apply_available_credit') }}</ion-label>
            </div>
            <div>
              <!-- Use ion-toggle instead of ion-radio, bind to a data property -->
              <ion-toggle
                :disabled="!availableCustomerCredits"
                slot="end"
                v-model="isCustomerCreditApplied"
                @ionChange="applyCustomerCredit"
                class="custom-toggle"
              ></ion-toggle>
            </div>
          </div>

          <div v-show="isCustomerCreditApplied">
            <div class="d-flex justify-space-between align-center my-3">
              <ion-label>
                <ion-text color="dark" class="pr-1">{{ $t('applied') }}</ion-text>
              </ion-label>
              <ion-label>
                <ion-text color="dark">({{ currencySymbol }})</ion-text>
              </ion-label>
              <ion-input
                v-model="customerCreditAmount"
                @ionChange="updateCustomerCreditAmount($event.target.value)"
                placeholder="Enter Credit Amount"
                class="fw-bold border-bottom text-center-input"
                style="margin-left: 2px"
              ></ion-input>
              <ion-button
                @click="updateCustomerCreditAmount(maximumCustomerCreditAmount)"
                slot="end"
                fill="outline"
                size="small"
              >
                Max
              </ion-button>
            </div>
          </div>
        </div>
      </div>

      <!-- subtotal -->
      <div v-if="!isAllItemsAreTaxInclusive || isCustomerCreditApplied" class="pa-3 bg-white mt-3">
        <div v-if="!isAllItemsAreTaxInclusive" class="my-3 pb-3 border-bottom d-flex justify-space-between">
          <div>
            <ion-label color="dark">{{ $t('subtotal') }}</ion-label>
          </div>
          <div>
            <ion-label color="primary" class="fw-bold">
              {{ priceFormatter(currencySymbol, displayPrice(grandSubtotal())) }}
            </ion-label>
          </div>
        </div>

        <!-- tax -->
        <div v-if="!isAllItemsAreTaxInclusive" class="mt-3 d-flex justify-space-between">
          <div>
            <ion-label color="dark">{{ $t('tax') }}</ion-label>
          </div>
          <div>
            <ion-label color="primary" class="fw-bold">
              {{ priceFormatter(currencySymbol, displayPrice(grandTax())) }}
            </ion-label>
          </div>
        </div>

        <!-- customerCredit -->
        <div v-if="isCustomerCreditApplied" class="mt-3 d-flex justify-space-between">
          <div>
            <ion-label color="dark">Credits Applied</ion-label>
          </div>
          <div>
            <ion-label color="primary" class="fw-bold">
              -{{ priceFormatter(currencySymbol, displayPrice(customerCreditAmount)) }}
            </ion-label>
          </div>
        </div>

        <!-- end -->
        <div class="pt-3"></div>
      </div>
    </ion-content>

    <ion-footer v-if="isDisplay && isOnline">
      <ion-toolbar>
        <div class="d-flex align-center justify-space-between pa-2">
          <div>
            <div>
              <ion-label color="dark">{{ $t('total_bill') }}</ion-label>
            </div>
            <div class="mt-2">
              <ion-label color="primary" class="fw-bold fs-3">
                {{ priceFormatter(currencySymbol, displayPrice(grandTotal())) }}
              </ion-label>
            </div>
          </div>
          <div>
            <ion-button
              class="ma-0 text-capitalize"
              color="primary"
              :disabled="disablePlaceOrder"
              @click="placeOrder"
              >{{ $t('place_order') }}</ion-button
            >
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
  <ion-loading
    :is-open="isOpenRef"
    cssClass="custom-loading"
    message=""
    spinner="crescent"
    @didDismiss="setOpen(false)"
  >
  </ion-loading>
  <!-- modal address -->
  <ion-modal :is-open="isOpenAddressRef" css-class="modal-address" @didDismiss="setOpenAddress(false)">
    <modal-address
      :isDelivery="typeAddress === TYPE_ADDRESS.DELIVERY"
      :selectedDeliveryAddress="selectedDeliveryAddress"
      :selectedBillingAddress="selectedBillingAddress"
      :allowAddNewAddress="canCheckoutByCreditCard"
      @close-modal="setOpenAddress(false)"
      @update-infor="updateInfor"
    ></modal-address>
  </ion-modal>
  <!-- modal time -->
  <ion-modal :is-open="isOpenTimeRef" css-class="modal-time" @didDismiss="setOpenTime(false)">
    <delivery-time-modal
      :deliveryDate="deliveryDateNearest"
      :deliveryTime="currentDateTime.data['deliveryTime']"
      :currentCartItems="groupedTenant[0]"
      :isNewOrder="true"
      :isSelectDeliveryOverPickup="isSelectDeliveryOverPickup"
      @confirm="confirmDateTime"
      @close-modal="setOpenTime(false)"
    ></delivery-time-modal>
  </ion-modal>

  <!-- modal credit card -->
  <ion-modal
    v-if="!isSubBuyer"
    :is-open="isOpenCreditCardRef"
    css-class="modal-credit-card"
    @didDismiss="setOpenCreditCard(false)"
  >
    <modal-credit-card
      :customerView="currentCustomerView.data"
      :isShowNetsCard="isShowNetsCard"
      :isShowFluidPayment="isFluidCustomer"
      :canCheckoutByCreditCard="canCheckoutByCreditCard"
      @choose-card="chooseCard"
      @close-modal="setOpenCreditCard(false)"
      @go-to-account-page="goToAccountPage"
    ></modal-credit-card>
  </ion-modal>

  <!-- modal add favorite -->
  <ion-modal
    :is-open="isOpenAddFavoriteRef"
    css-class="modal-confirm"
    @didDismiss="setOpenAddFavorite(false)"
    :backdropDismiss="false"
  >
    <modal-add-favorite :data="dataFavoriteSkuIds" @close-modal="closeModalAddFavorite"></modal-add-favorite>
  </ion-modal>

  <!-- modal order confirm -->
  <ion-modal
    :is-open="isOpenOrderConfirmRef"
    css-class="modal-confirm"
    @didDismiss="setOpenOrderConfirm(false)"
    :backdropDismiss="false"
  >
    <modal-order-confirm
      :title="titleOrderConfirm"
      :subTitle="subTitleOrderConfirm"
      @on-yes="checkDuplicateOrderFunction"
      @on-no="setOpenOrderConfirm(false)"
    ></modal-order-confirm>
  </ion-modal>
  <!-- modal blacklist feature -->
  <!-- <ion-modal :is-open="isOpenModalBlackList" css-class="modal-confirm" :backdropDismiss="false"> -->
  <ModalBlacklist
    v-if="isOpenModalBlackList"
    :key="renderBlacklist"
    @on-no="setOpenModalBlackList(false)"
    :message="messageBlackList"
  />
  <!-- </ion-modal> -->
  <!-- modal-cuisines-preferences -->
  <ion-modal :is-open="isOpenModalCuisine" :backdropDismiss="false">
    <CuisinePreferences
      :customerId="selectedCompany.id"
      :countryId="user.country.id"
      :isNewCompany="false"
      @later="handleCloasingCuisine()"
      @apply="handleCloasingCuisine()"
    />
  </ion-modal>
  <!-- modal-cuisines-preferences -->
  <!-- modal notification check duplicate order -->
  <modal-check-duplicate-order
    :key="reRender"
    v-if="isOpenCheckDuplicatieOrder"
    :title="titleCheckDuplicatieOrder"
    :subTitle="subTitleCheckDuplicatieOrder"
    :countModalCheckDuplicate="countModalCheckDuplicate"
    @on-yes="openCheckDuplicatieOrder"
    @on-no="skipCheckDuplicatieOrder"
  ></modal-check-duplicate-order>

  <!-- Items no longer available alert modal -->
  <ion-modal
    mode="ios"
    backdrop-dismiss="false"
    :is-open="isOpenItemsNoAvailable"
    css-class="modal-price-updated"
    @didDismiss="closeNoAvailableItems"
  >
    <alert-info-modal
      :message="$t('some_item_no_available')"
      :buttonText="$t('return_to_cart')"
      @close-modal="closeNoAvailableItems"
    ></alert-info-modal>
  </ion-modal>

  <!-- modal Paynow QR Code instruction -->
  <ion-modal
    :is-open="isOpenPaymentInstruction"
    css-class="modal-paynow-info"
    @didDismiss="setOpenPaymentInstruction(false)"
    :backdropDismiss="false"
  >
    <modal-paynow-instruction @on-ok="onClosePaymentInstruction()"></modal-paynow-instruction>
  </ion-modal>
</template>
<script>
import NetsCardImage from '@/assets/images/nets-brand.png';
import ModalAddFavorite from '@/modules/b2b/components/ModalAddFavorite.vue';
import ModalAddress from '@/modules/b2b/components/ModalAddress.vue';
import ModalBlacklist from '@/modules/b2b/components/ModalBlacklist.vue';
import ModalCheckDuplicateOrder from '@/modules/b2b/components/ModalCheckDuplicateOrder.vue';
import ModalCreditCard from '@/modules/b2b/components/ModalCreditCard.vue';
import ModalPaynowInstruction from '@/modules/b2b/components/ModalPaynowInstruction.vue';
import { DEFAULT_ORDER, PAYMENT_METHOD, SUB_BUYER_ID, TYPE_ADDRESS } from '@/modules/b2b/constants';
import { displayPrice, getAddressFormat, randomId } from '@/modules/b2b/services/libs/helper';
import { stripe } from '@/modules/b2b/services/libs/payment';
import { ACTIONS as ACTIONS_ADDRESS } from '@/modules/b2b/store/address/actions';
import { ACTIONS as ACTIONS_CART } from '@/modules/b2b/store/cart/actions';
import { ACTIONS as ACTIONS_CUSTOMER } from '@/modules/b2b/store/customer/actions';
import { ACTIONS as ACTIONS_ORDER } from '@/modules/b2b/store/order/actions';
import { ACTIONS as ACTIONS_PAYMENT } from '@/modules/b2b/store/payment/actions';
import { ACTIONS as ACTIONS_PRODUCT } from '@/modules/b2b/store/product/actions';
import { DELIVERY_TYPES } from '@/modules/shared/constants';
import { getTenantDefaultPickupAddress, getTenantDeliveryOptions } from '@/modules/shared/services/graphql';
import ModalOrderConfirm from '@/modules/shared/views/cart/NewModalOrderConfirm.vue';
// import Payment from '@/plugins/nets-payment-plugin.js';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { alertController, getPlatforms, isPlatform } from '@ionic/vue';
import dayjs from 'dayjs';
import {
  arrowDownOutline,
  businessOutline,
  cardOutline,
  cashOutline,
  chevronBackOutline,
  chevronForwardOutline,
  helpCircleOutline,
  storefrontOutline
} from 'ionicons/icons';
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { createNamespacedHelpers, useStore } from 'vuex';
import ProductItemCheckout from '../ProductItemCheckout.vue';
import { getCartItems } from './functions';
const { mapGetters: mapGettersCart, mapActions: mapActionsCart } = createNamespacedHelpers('b2b/cart');
const { mapGetters: mapGettersAddress, mapActions: mapActionsAddress } = createNamespacedHelpers(
  'b2b/address'
);
const { mapGetters: mapGettersCustomer, mapActions: mapActionsCustomer } = createNamespacedHelpers(
  'b2b/customer'
);
const { mapActions: mapActionsPayment, mapGetters: mapGettersPayment } = createNamespacedHelpers(
  'b2b/payment'
);
const { mapGetters: mapGettersOrder, mapActions: mapActionsOrder } = createNamespacedHelpers('b2b/order');
const { mapActions: mapActionsProduct } = createNamespacedHelpers('b2b/product');

import { apolloClient } from '@/main';
import { FLUID_ICON } from '@/modules/b2b/constants';
import {
  CheckCustomerCuisines,
  checkBlackListOrder,
  getFluidCustomerInfo
} from '@/modules/b2b/services/graphql';
import { ORDER_BLOCK_STATUS } from '@/modules/shared/constants';
import { useLoading } from '@/modules/shared/utils/';
import { setBadgeNumber } from '@/modules/shared/utils/badge';
import { getAvailableDeliverySlots, getSupplierContactInfo } from '@/services/shared/graphql';
import { PAYMENT_TRANSACTION_STATUS } from '@/services/shared/helper/constants';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils/';
import { calDueDate } from '@/utils/datetime';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { toastController } from '@ionic/vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    itemsSelected: {
      type: Object || Array,
      required: true
    },
    cartData: {
      type: Object || Array,
      required: true
    }
  },

  components: {
    // components
    ModalBlacklist,
    ModalAddress,
    ProductItemCheckout,
    ModalCreditCard,
    ModalAddFavorite,
    ModalOrderConfirm,
    ModalPaynowInstruction,
    ModalCheckDuplicateOrder,
    CuisinePreferences: defineAsyncComponent(() =>
      import('@/modules/shared/views/cuisine-preferences/index.vue')
    ),
    AlertInfoModal: defineAsyncComponent(() => import('@/modules/shared/components/AlertInfoModal.vue')),
    DeliveryTimeModal: defineAsyncComponent(() =>
      import('@/modules/shared/views/orders/list/components/DeliveryTimeModal.vue')
    )
  },

  emits: ['close-modal', 'place-order', 'refresh-cart'],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const user = ref(null);
    const selectedCompany = ref(null);
    const { formatDayDate, formatDateMonthYear } = useDateFormatter();
    //modal check notification duplicate order
    const isOpenCheckDuplicatieOrder = ref(false);
    const setOpenCheckDuplicatieOrder = (state) => (isOpenCheckDuplicatieOrder.value = state);
    // modal address
    const isOpenAddressRef = ref(false);
    const setOpenAddress = (state) => (isOpenAddressRef.value = state);
    // modal order confirm
    const isOpenOrderConfirmRef = ref(false);
    const setOpenOrderConfirm = (state) => (isOpenOrderConfirmRef.value = state);
    // modal time
    const isOpenTimeRef = ref(false);
    const setOpenTime = (state) => (isOpenTimeRef.value = state);
    // modal add favorite
    const isOpenAddFavoriteRef = ref(false);
    const setOpenAddFavorite = (state) => (isOpenAddFavoriteRef.value = state);
    // modal credit card
    const isOpenCreditCardRef = ref(false);
    const setOpenCreditCard = (state) => (isOpenCreditCardRef.value = state);
    //modal black list feature
    const isOpenModalBlackList = ref(false);
    const setOpenModalBlackList = (state) => (isOpenModalBlackList.value = state);
    const paynowSessionLink = ref('');
    const setPaynowSessionLink = (state) => (paynowSessionLink.value = state);
    const isOpenPaymentInstruction = ref(false);
    const setOpenPaymentInstruction = (state) => (isOpenPaymentInstruction.value = state);
    const isOpenItemsNoAvailable = ref(false);
    const setOpenItemsNoAvailable = (state) => (isOpenItemsNoAvailable.value = state);
    const closeNoAvailableItems = () => {
      setOpenItemsNoAvailable(false);
      emit('close-modal');
      emit('refresh-cart');
    };

    // loading
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    const reRender = ref(1);
    const messageDuplicate = ref('');
    const { showLoading } = useLoading();

    const currentDateTime = ref({
      index: 0,
      data: {
        deliveryDate: '',
        deliveryTime: ''
      }
    });
    const currentCustomerView = ref({
      index: 0,
      data: null
    });
    const uniqueId = () => randomId();
    const typeAddress = ref('');
    const selectedDeliveryAddress = ref(null);
    const selectedBillingAddress = ref(null);
    const setSelectedDeliveryAddress = (state) => (selectedDeliveryAddress.value = state);
    const setSelectedBillingAddress = (state) => (selectedBillingAddress.value = state);
    const tenantIds = ref([]);
    const data = ref(DEFAULT_ORDER);
    const listCards = ref([]);
    const listNetsCardShow = ref([]);
    const dataFavoriteSkuIds = ref(null);
    const titleOrderConfirm = ref('');
    const subTitleOrderConfirm = ref('');
    const titleCheckDuplicatieOrder = ref('');
    const subTitleCheckDuplicatieOrder = ref('');
    const groupedTenant = ref([]);

    const getCartItemsData = getCartItems(props.itemsSelected);
    const deliverySlots = ref([]);
    data.value.cartItems = getCartItemsData.cartItems;
    tenantIds.value = getCartItemsData.tenantIds;
    listCards.value = getCartItemsData.listCards;
    const listSkuIds = ref([]);
    const getCompany = ref(null);
    const countModalCheckDuplicate = ref(null);
    const isDisplay = ref(false);
    const priceCaculate = ref([]);
    const messageBlackList = ref(null);
    const dates = ref([]);
    const tomorrowDay = ref('');
    const deliveryDateNearest = ref('');
    const renderBlacklist = ref(1);
    const isOpenModalCuisine = ref(false);
    const setOpenModalCuisine = (state) => (isOpenModalCuisine.value = state);
    const isSelectDeliveryOverPickup = ref(true);
    const deliveryOptions = ref(DELIVERY_TYPES.DELIVERY);
    const pickupAddress = ref(null);
    const isPickingDeliveryAndBilling = ref({
      isSelectDelivery: false,
      isSelectBilling: false
    });
    const isB2cCustomer = ref(false);
    const DELIVERY_OPTIONS = [
      DELIVERY_TYPES.DELIVERY,
      DELIVERY_TYPES.PICKUP,
      DELIVERY_TYPES.DELIVERY_AND_PICKUP
    ];
    const getDefaultDeliveryTime = (dataCartItems) => {
      const selectedDateObject = deliverySlots.value.find(
        (dateObj) => dateObj.date === dataCartItems.deliveryDate
      );
      if (selectedDateObject) {
        // Filter slots with isAvailable set to true and extract the label values
        const checkAvailableSlotsLabels = selectedDateObject.slots
          .filter((slot) => slot.isAvailable)
          .map((slot) => slot.label);

        data.value.cartItems[currentDateTime.value.index].deliveryTime = getEarliestTime(
          checkAvailableSlotsLabels
        );
      } else {
        data.value.cartItems[currentDateTime.value.index].deliveryTime = dataCartItems.deliveryTime;
      }
    };

    const getEarliestTime = (timeSlots) => {
      // Find the earliest time using string comparison
      const earliestTime = timeSlots.reduce((earliest, current) => {
        const [earliestStart] = earliest.split('-');
        const [currentStart] = current.split('-');

        return currentStart < earliestStart ? current : earliest;
      });

      return earliestTime;
    };

    const setIsPickingDeliveryAndBilling = (isSelectDelivery, isSelectBilling) => {
      isPickingDeliveryAndBilling.value.isSelectDelivery = isSelectDelivery;
      isPickingDeliveryAndBilling.value.isSelectBilling = isSelectBilling;
    };
    const isStripePaymentError = ref(false);
    return {
      isSelectDeliveryOverPickup,
      isPickingDeliveryAndBilling,
      setIsPickingDeliveryAndBilling,
      user,
      router,
      showLoading,
      selectedCompany,
      isOpenCheckDuplicatieOrder,
      setOpenCheckDuplicatieOrder,
      isOpenAddressRef,
      setOpenAddress,
      typeAddress,
      TYPE_ADDRESS,
      selectedDeliveryAddress,
      selectedBillingAddress,
      setSelectedDeliveryAddress,
      setSelectedBillingAddress,
      data,
      displayPrice,
      isOpenTimeRef,
      setOpenTime,
      currentDateTime,
      uniqueId,
      tenantIds,
      getCartItems,
      getCartItemsData,
      getDefaultDeliveryTime,
      isOpenCreditCardRef,
      setOpenCreditCard,
      currentCustomerView,
      listCards,
      listNetsCardShow,
      setOpenAddFavorite,
      isOpenAddFavoriteRef,
      dataFavoriteSkuIds,
      listSkuIds,
      setOpenOrderConfirm,
      isOpenOrderConfirmRef,
      titleOrderConfirm,
      subTitleOrderConfirm,
      titleCheckDuplicatieOrder,
      subTitleCheckDuplicatieOrder,
      groupedTenant,
      isOpenModalBlackList,
      setOpenModalBlackList,
      messageBlackList,
      isDisplay,
      dates,
      tomorrowDay,
      deliveryDateNearest,
      dayjs,
      renderBlacklist,
      reRender,
      messageDuplicate,
      priceFormatter,
      isStripePaymentError,
      isOpenItemsNoAvailable,
      setOpenItemsNoAvailable,
      closeNoAvailableItems,
      // icons
      arrowDownOutline,
      chevronBackOutline,
      businessOutline,
      storefrontOutline,
      cardOutline,
      chevronForwardOutline,
      cashOutline,
      getCompany,
      isOpenRef,
      setOpen,
      NetsCardImage,
      countModalCheckDuplicate,
      priceCaculate,
      isOpenModalCuisine,
      setOpenModalCuisine,
      helpCircleOutline,
      calDueDate,
      paynowSessionLink,
      setPaynowSessionLink,
      isOpenPaymentInstruction,
      setOpenPaymentInstruction,
      PAYMENT_TRANSACTION_STATUS,
      InAppBrowser,
      FLUID_ICON,
      deliverySlots,
      isOnline: ref(true),
      store,
      formatDayDate,
      formatDateMonthYear,
      PAYMENT_METHOD,
      isB2cCustomer,
      DELIVERY_OPTIONS,
      deliveryOptions,
      pickupAddress,
      DELIVERY_TYPES
    };
  },

  inject: ['$storage'],
  data() {
    return {
      t: useI18n(),
      isShowNetsCard: false,
      merchant_token: '',
      deviceId: '',
      currectBrowserUrl: '',
      paymentInfo: null,
      qrCodeTimer: null,
      isFluidCustomer: false,
      orderId: 0,
      isCustomerCreditApplied: false,
      customerCreditAmount: null
    };
  },
  unmounted() {
    this.setIsPickingDeliveryAndBilling(false, false);
  },
  async mounted() {
    this.groupedTenant = this.itemsSelected;
    // get device id
    this.deviceId = (await Device.getId()).uuid;
    await this.showLoading(() =>
      Promise.allSettled([
        this.$storage.getUser(),
        this.$storage.getSelectedCompany(),
        this.$storage.getSelectCustomer()
      ])
        .then((values) => {
          this.user = values[0].value;
          this.selectedCompany = values[1].value;
          this.selectedCustomer = values[2].value;
          this.isB2cCustomer = this.selectedCustomer.is_b2c;
        })
        .then(() =>
          Promise.allSettled([
            this[ACTIONS_PAYMENT.GET_LIST_CUSTOMER_CARD](this.selectedCompany.id),
            this[ACTIONS_PAYMENT.GET_DEFAULT_CARD](this.selectedCompany.id),
            this[ACTIONS_ADDRESS.GET_ADDRESSES]({
              supplierId: this.selectedCompany.id,
              active: true
            }),
            this[ACTIONS_CUSTOMER.GET_CUSTOMER_VIEW]({
              id: this.selectedCompany.id,
              tenantIds: this.tenantIds
            }),
            // check fluid customer
            this.handleCheckFluidCustomer(),
            this[ACTIONS_PAYMENT.GET_LIST_NETS_CARD](this.deviceId)
          ]).then(() => {
            return Promise.allSettled([
              this.handleGetDeliveryDate(),
              this.checkCardDefault(),
              this.getAddressDefault(),
              this.getXeroCustomer(),
              this.updateCustomerCreditAmount(this.maximumCustomerCreditAmount)
            ]);
          })
        )
    );

    this.data = {
      ...this.data,
      customerId: this.selectedCompany.id,
      deliveryAddressId: this.selectedDeliveryAddress,
      billingAddressId: this.selectedBillingAddress,
      isMobile: Capacitor.isNativePlatform()
    };
    this.handleGetTomorrowDay();
    this.setDefaultPaymentMethod();
    this.getDefaultDeliveryTime(this.data.cartItems[this.currentDateTime.index]);
    this.isDisplay = true;
    this.getTenantDeliveryOptions();
  },

  computed: {
    ...mapGettersAddress(['list']),
    ...mapGettersCart(['cart', 'status', 'checkDuplicateOrder']),
    ...mapGettersCustomer(['customerView', 'listData']),
    ...mapGettersOrder(['message', 'error', 'addOrderRes']),
    ...mapGettersPayment(['xeroCustomer', 'cardList', 'defaultCardId', 'listNetsCard']),
    addressDefault() {
      return (id) => {
        if (this.isSelectDeliveryOverPickup) {
          if (!id || !this.list) return;
          const index = this.list.findIndex((item) => item.id === id);
          return index !== -1 ? getAddressFormat(this.list[index]) : '';
        } else {
          return this.pickupAddress?.streetName;
        }
      };
    },
    isPreSelectedAddress() {
      return this.list.filter((item) => item.is_default === true).length === 1 || this.list.length === 1;
    },
    isMustSelectAddress() {
      return this.list.length < 1 || !this.isPreSelectedAddress;
    },
    cartList() {
      return this.cartData.items.filter((item) => item.selected === true);
    },
    currencySymbol() {
      return this.user && this.user.currency_symbol;
    },
    selectedItems() {
      return Object.values(this.groupedTenant)
        .flat()
        .filter((item) => item.selected);
    },
    availableItems() {
      return this.selectedItems.filter((item) => item.available);
    },
    disablePlaceOrder() {
      if (this.user && this.user.is_b2c) {
        if (!this.canCheckoutByCreditCard) return false;

        const index = this.currentCustomerView.index;
        if (this.data.cartItems[index].paymentMethod !== PAYMENT_METHOD.STRIPE) {
          return true;
        }
      }

      return (
        !this.data.deliveryAddressId ||
        !this.data.billingAddressId ||
        !this.deliveryDateNearest ||
        this.hasUpdateAddress
      );
    },
    displayDeliveryDateNearest() {
      if (dayjs().format('YYYY-MM-DD') === this.deliveryDateNearest) return this.$t('today');
      return this.tomorrowDay === this.deliveryDateNearest
        ? this.$t('tomorrow') + ', ' + this.formatDateMonthYear(this.deliveryDateNearest)
        : this.formatDayDate(this.deliveryDateNearest);
    },
    isSubBuyer() {
      return this.user && this.user.user_type_id === SUB_BUYER_ID;
    },
    availableTomorrowDay() {
      return this.tomorrowDay === this.deliveryDateNearest;
    },
    isShowNextDay() {
      let key = this.dates?.findIndex((date) => date.data === this.tomorrowDay);
      return !!this.dates?.[key]?.isAvailable;
    },
    hasUpdateAddress() {
      if (this.list.length > 1) {
        return (
          !this.isPickingDeliveryAndBilling.isSelectDelivery ||
          !this.isPickingDeliveryAndBilling.isSelectBilling
        );
      } else {
        return false;
      }
    },
    availableCustomerCredits() {
      const creditAmount =
        Number(this.xeroCustomer?.overpayment_amount || 0) +
        Number(this.xeroCustomer?.credit_notes_amount || 0);
      if (creditAmount < 0) {
        return 0;
      } else {
        return creditAmount;
      }
    },
    maximumCustomerCreditAmount() {
      const grandTotal = displayPrice(this.grandSubtotal() + this.grandTax());
      return this.availableCustomerCredits > grandTotal ? grandTotal : this.availableCustomerCredits;
    },
    canCheckoutByCustomerCredit() {
      return this.customerView[0]?.can_checkout_by_customer_credit;
    },
    canCheckoutByCreditCard() {
      return this.customerView[0]?.can_checkout_by_credit_card;
    },
    isAllItemsAreTaxInclusive() {
      return this.selectedItems.every((item) => item.price_is_tax_inclusive);
    }
  },

  // methods
  methods: {
    ...mapActionsAddress([ACTIONS_ADDRESS.GET_ADDRESSES]),
    ...mapActionsCart([
      ACTIONS_CART.GET_CART_ITEMS,
      ACTIONS_CART.CHECK_CLOSING_TIME,
      ACTIONS_CART.CHECK_DUPLICATE_ORDER,
      ACTIONS_CART.FINISH_ORDER,
      ACTIONS_CART.STORE_3D_SECURE_PROCESS
    ]),
    ...mapActionsOrder([
      ACTIONS_ORDER.SET_FAILURE,
      ACTIONS_ORDER.ADD_ORDER,
      ACTIONS_ORDER.CHECK_DOUBLE_ORDER,
      ACTIONS_ORDER.NETS_PAYMENT_PIN
    ]),
    ...mapActionsCustomer([ACTIONS_CUSTOMER.GET_CUSTOMER_VIEW, ACTIONS_CUSTOMER.GET_CUSTOMER_DETAILS]),
    ...mapActionsProduct([ACTIONS_PRODUCT.ADD_FAVORITE, ACTIONS_PRODUCT.CHECK_FAVORITE_SKUS]),
    ...mapActionsPayment([
      ACTIONS_PAYMENT.GET_LIST_CUSTOMER_CARD,
      ACTIONS_PAYMENT.GET_DEFAULT_CARD,
      ACTIONS_PAYMENT.GET_LIST_NETS_CARD,
      ACTIONS_PAYMENT.REMOVE_NETS_CARD,
      ACTIONS_PAYMENT.GET_XERO_CUSTOMER
    ]),
    async handleRefresh(event) {
      await this.detectConnection();
      event.target.complete();
    },
    handleGetTomorrowDay() {
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      this.tomorrowDay = dayjs(new Date(currentDate)).format('YYYY-MM-DD');
    },
    setDefaultPaymentMethod() {
      if (!this.user.is_b2c) return;
      const index = this.currentCustomerView.index;
      this.listCards[index]?.id && (this.data.cartItems[index].paymentMethod = PAYMENT_METHOD.STRIPE);
    },
    async handleCheckFluidCustomer() {
      const { data } = await apolloClient.query({
        query: getFluidCustomerInfo,
        variables: {
          tenantId: this.user.tenant.id,
          customerId: this.selectedCompany.id
        }
      });

      this.isFluidCustomer = !!data.getFluidCustomerInfo;
      const index = this.currentCustomerView.index;
      this.isFluidCustomer && (this.data.cartItems[index].paymentMethod = PAYMENT_METHOD.FLUID);
    },
    async getTenantDeliveryOptions() {
      const { data } = await apolloClient.query({
        query: getTenantDeliveryOptions,
        variables: {
          tenantId: this.user.tenant.id
        }
      });
      const options = data?.getTenantDeliveryOptions;
      if (!options || !this.DELIVERY_OPTIONS.includes(options)) {
        this.deliveryOptions = DELIVERY_TYPES.DELIVERY;
        this.isSelectDeliveryOverPickup = true;
      }
      if (options === DELIVERY_TYPES.PICKUP) {
        this.isSelectDeliveryOverPickup = false;
      }
      if ([DELIVERY_TYPES.PICKUP, DELIVERY_TYPES.DELIVERY_AND_PICKUP].includes(options))
        this.getTenantDefaultPickupAddress();
      this.deliveryOptions = options;
    },
    async getTenantDefaultPickupAddress() {
      const { data } = await apolloClient.query({
        query: getTenantDefaultPickupAddress,
        variables: {
          tenantId: this.user.tenant.id
        }
      });
      this.pickupAddress = data?.getTenantDefaultPickupAddress;
    },
    async handleGetDeliveryDate() {
      let items = [];
      for (const value of this.groupedTenant[0]) {
        items.push({
          quantity: value.quantity,
          sku_id: value.sku_id
        });
      }

      const currentTime = new Date();
      const { data } = await apolloClient.query({
        query: getAvailableDeliverySlots,
        variables: {
          userActionDatetime: dayjs(currentTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          items: items,
          tenantId: this.user.tenant.id,
          customerId: this.selectedCustomer.id,
          daysLimit: 30,
          isNewOrder: true
        }
      });

      this.deliverySlots = data.getAvailableDeliverySlots;

      let deliveryDateNearest = '';

      outerLoop: for (const ds of this.deliverySlots) {
        for (const slot of ds.slots) {
          if (slot.isAvailable) {
            this.data.cartItems.forEach((element) => {
              if (element.deliveryTime == '') {
                element.deliveryTime = slot.label;
              }
            });
            deliveryDateNearest = ds.date;
            break outerLoop;
          }
        }
      }

      const startDate = new Date();
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 30);

      this.dates = this.deliverySlots.map((ds) => ({ data: ds.date, isAvailable: ds.isAvailable }));

      if (deliveryDateNearest.length === 0) deliveryDateNearest = this.tomorrowDay;
      for (let i in this.data.cartItems) {
        this.data.cartItems[i].deliveryDate = `${deliveryDateNearest}`;
      }
      this.deliveryDateNearest = deliveryDateNearest;
    },
    isTimeSlotAvailable(date, deliveryTime) {
      let isAvailable = false;

      for (const ds of this.deliverySlots) {
        if (ds.date === date) {
          for (const slot of ds.slots) {
            if (slot.label === deliveryTime) {
              isAvailable = slot.isAvailable;
              break;
            }
          }
        }
      }

      return isAvailable;
    },
    async checkCardDefault() {
      if (this.isFluidCustomer || !this.canCheckoutByCreditCard) {
        return; // do nothing with Fluid customer card
      }
      if (this.listNetsCard.length > 0 && isPlatform('mobile')) {
        const findCardDefault = this.listNetsCard[0];
        this.merchant_token = findCardDefault.merchant_token;
        for (var i = 0; i < this.groupedTenant.length; i++) {
          if (this.groupedTenant[i][0].tenant_id === 1 || this.groupedTenant[i][0].tenant_id == 7) {
            this.priceCaculate.push(0);
            for (var k = 0; k < this.groupedTenant[i].length; k++) {
              this.priceCaculate[i] =
                parseFloat(this.priceCaculate[i]) +
                parseFloat(this.groupedTenant[i][k].total_price) +
                parseFloat(this.groupedTenant[i][k].tax);
            }
          }
        }
        for (var value = 0; value < this.priceCaculate.length; value++) {
          if (this.user && this.user.country.name === 'SG') {
            this.listCards[value] = findCardDefault;
            this.data.cartItems[value].netsT0205 = 'netsCard';
            this.data.cartItems[value].netCardId = findCardDefault.id;
          }
        }
      } else {
        // find default cardd based on setting of user
        let defaultCard = this.cardList.find((item) => item.id === this.defaultCardId);
        // get first card if user don't set any card as default
        if (!defaultCard & (this.cardList.length > 0)) defaultCard = this.cardList[0];
        for (var key = 0; key < this.groupedTenant.length; key++) {
          if (this.groupedTenant[key][0].tenant_id === 1 || this.groupedTenant[key][0].tenant_id == 7) {
            this.listCards[key] = defaultCard ? defaultCard : this.listCards[key];
            this.data.cartItems[key].stripeCustomerId = this.user.stripe_customer_id;
            this.data.cartItems[key].stripeCardId = this.listCards ? this.listCards[key].id : null;
          }
        }
      }
    },

    getCardImage(brand) {
      return stripe.getCardImage(brand);
    },

    chooseCard(card) {
      const index = this.currentCustomerView.index;
      if (card.card_type === PAYMENT_METHOD.STRIPE) {
        this.listCards[index] = {
          ...this.listCards[index],
          ...card
        };
        this.data.cartItems[index].stripeCustomerId = this.user.stripe_customer_id;
        this.data.cartItems[index].stripeCardId = card ? card.id : null;
        this.data.cartItems[index].paymentMethod = PAYMENT_METHOD.STRIPE;
        delete this.data.cartItems[index].netsT0205;
        delete this.data.cartItems[index].netCardId;
      } else if (card.card_type === PAYMENT_METHOD.NETS) {
        this.listCards[index] = {
          ...this.listCards[index],
          ...card
        };
        this.data.cartItems[index].netsT0205 = 'netsCard';
        this.data.cartItems[index].netCardId = card.id;
        this.data.cartItems[index].paymentMethod = PAYMENT_METHOD.NETS;
      } else if (card.card_type === PAYMENT_METHOD.PAYNOW) {
        this.data.cartItems[index].paymentMethod = PAYMENT_METHOD.PAYNOW;
      } else if (card.card_type === PAYMENT_METHOD.FLUID) {
        this.data.cartItems[index].paymentMethod = PAYMENT_METHOD.FLUID;
      } else {
        this.data.cartItems[index].paymentMethod = null;
      }

      setTimeout(() => this.setOpenCreditCard(false), 400);
    },

    async confirmDateTime(data) {
      const index = this.currentDateTime.index;
      this.data.cartItems[index] = {
        ...this.data.cartItems[index],
        ...data
      };
      this.deliveryDateNearest = data.deliveryDate;
      setTimeout(() => this.setOpenTime(false), 200);
    },

    chooseTime(index) {
      this.currentDateTime = {
        ...this.currentDateTime,
        index,
        data: {
          deliveryDate: this.data.cartItems[index].deliveryDate,
          deliveryTime: this.data.cartItems[index].deliveryTime
        }
      };

      this.setOpenTime(true);
    },

    openModalCreditCard(index) {
      // limit amount for net Payment removed by Cheryl request
      // this.priceCaculate[index]?.toFixed(2) < VUE_APP_NETS_LIMIT
      //   ? (this.isShowNetsCard = true)
      //   : (this.isShowNetsCard = false);

      // only show NETS payment for SG
      if (this.user && this.user.country.name === 'SG') this.isShowNetsCard = true;

      this.currentCustomerView = {
        index,
        data: this.customerView[index]
      };
      this.setOpenCreditCard(true);
    },

    grandSubtotal() {
      return parseFloat(
        this.groupedTenant.reduce((total, tenantItems) => {
          total += tenantItems
            .filter((v) => v.available || !v.available)
            .reduce((total, v) => total + v.total_price_tax_exclusive, 0);
          return total;
        }, 0)
      );
    },
    grandTax() {
      return parseFloat(
        this.groupedTenant.reduce((total, tenantItems) => {
          total += tenantItems
            .filter((v) => v.available || !v.available)
            .reduce((total, v) => total + v.tax, 0);
          return total;
        }, 0)
      );
    },
    grandTotal() {
      return this.grandSubtotal() + this.grandTax() - this.customerCreditAmount;
    },

    async getAddressDefault() {
      this.setSelectedDeliveryAddress(await this.$storage.getSelectedDeliveryAddress());
      // selectedDeliveryAddress
      if (this.list.length <= 1) {
        this.updateSelectedAddress(true, this.selectedDeliveryAddress);
        this.updateSelectedAddress(false, this.selectedBillingAddress);
      }
      // selectedBillingAddress
      if (this.isPreSelectedAddress) {
        this.setSelectedBillingAddress(this.list[0].id);
        this.updateSelectedAddress(false, this.list[0].id);
        this.setIsPickingDeliveryAndBilling(false, true);
      }
    },

    updateSelectedAddress(isDelivery, id) {
      const addresses = this.list;
      if (!id) {
        this.updateSelectedAddressToStorage(isDelivery, addresses[0].id);
      } else {
        const index = addresses.findIndex((item) => item.id === id);
        if (index === -1) {
          this.updateSelectedAddressToStorage(isDelivery, addresses[0].id);
        } else {
          this.updateSelectedAddressToStorage(isDelivery, id);
        }
      }
    },

    updateSelectedAddressToStorage(isDelivery, id) {
      if (isDelivery) {
        this.setSelectedDeliveryAddress(id);
        this.data.deliveryAddressId = id;
        this.$storage.setSelectedDeliveryAddress(id);
        if (this.list.length > 1) {
          this.setIsPickingDeliveryAndBilling(true, this.isPickingDeliveryAndBilling.isSelectBilling);
        }
      } else {
        this.setSelectedBillingAddress(id);
        this.data.billingAddressId = id;
        this.$storage.setSelectedBillingAddress(id);
        if (this.list.length > 1) {
          this.setIsPickingDeliveryAndBilling(this.isPickingDeliveryAndBilling.isSelectDelivery, true);
        }
      }
    },
    openModalAddress(type) {
      this.typeAddress = type;
      this.setOpenAddress(true);
    },

    updateInfor({ isDelivery, selectedAddressId }) {
      this.setOpenAddress(false);
      this.updateSelectedAddressToStorage(isDelivery, selectedAddressId);
    },
    async placeOrder() {
      this.setOpen(true);
      try {
        const deliveryAddress = this.addressDefault(this.selectedDeliveryAddress);
        const tenantId = this.user.tenant.id;
        this.groupedTenant.map((items, index) => {
          const cartItemIds = items.reduce(
            (ids, item) => (item.available || !item.available ? [...ids, item.id] : ids),
            []
          );
          this.data.cartItems[index].cartItemIds = cartItemIds;
        });
        const items = {
          ...this.data,
          deliveryAddress: deliveryAddress,
          tenantId: tenantId
        };
        const { data } = await apolloClient.mutate({
          mutation: checkBlackListOrder,
          variables: items
        });
        this.setOpen(false);
        if (data.checkBlackListOrder) {
          this.messageBlackList = data.checkBlackListOrder;
          this.renderBlacklist++;
          this.setOpenModalBlackList(true);
        } else {
          await this.checkFavoriteSkus();
        }
      } catch (e) {
        this.setOpen(false);
        this.showAlertError();
      }
    },

    async checkFavoriteSkus() {
      this.setOpen(true);
      const listSkusNotFavorite = await this[ACTIONS_PRODUCT.CHECK_FAVORITE_SKUS]({
        customerId: this.selectedCompany.id,
        accountNumber: this.selectedCompany.account_number,
        listSKUs: this.listSkuIds
      });
      this.setOpen(false);
      const isFavorite = listSkusNotFavorite.length > 0;
      if (isFavorite) {
        this.dataFavoriteSkuIds = {
          account_number: this.selectedCompany.account_number,
          user_id: this.selectedCompany.id,
          list_sku_id: listSkusNotFavorite
        };
        this.setOpenAddFavorite(true);
      } else {
        await this.confirmOrder();
      }
    },

    async confirmOrder() {
      this.setOpen(true);
      await this[ACTIONS_CUSTOMER.GET_CUSTOMER_DETAILS]({
        id: this.selectedCompany.id
      });
      if (this.listData?.data[0]?.persons.length == 0) {
        this.setOpen(false);
        setTimeout(async () => {
          await this.showAlert({
            message: this.$t('orderB2b.addPICMessage')
          });
        }, 500);
        return;
      } else {
        this.setOpen(true);
        const checkDoubleOrder = await this[ACTIONS_ORDER.CHECK_DOUBLE_ORDER]();
        this.setOpen(false);

        if (checkDoubleOrder) {
          this.titleOrderConfirm = this.$t('confirm_submission');
          this.subTitleOrderConfirm = `${this.$t('your_order')} #${checkDoubleOrder} ${this.$t(
            'confirm_order_5_mins'
          )}`;
        } else {
          this.titleOrderConfirm = this.$t('confirmation');
          this.subTitleOrderConfirm = this.$t('continue_order');
        }

        setTimeout(() => this.setOpenOrderConfirm(true), 500);
      }
    },
    detectConnection() {
      const connection = navigator.onLine;
      if (connection) {
        this.isOnline = true;
      } else {
        this.isOnline = false;
      }
    },
    async showAlertError() {
      const alert = await alertController.create({
        header: 'Oops!',
        mode: 'ios',
        message: this.$t('something_went_wrong_please_try_again'),
        buttons: [
          {
            text: this.$t('OK'),
            handler: async () => {
              await this.detectConnection();
            }
          }
        ]
      });
      await alert.present();
    },
    async showAlert({ header, message }) {
      const isCustomerDeleted = this.error?.extensions?.errorState === 'CUSTOMER_DELETED';
      const alert = await alertController.create({
        mode: 'ios',
        header,
        message,
        backdropDismiss: !isCustomerDeleted,
        buttons: isCustomerDeleted
          ? [
              {
                text: this.$t('close'),
                handler: async () => {
                  this.router.push(`/b2b/select-customer`);
                }
              }
            ]
          : [this.$t('close')]
      });
      setTimeout(async () => await alert.present(), 500);
      this.getCompany = await this.$storage.getSelectedCompany();
      setBadgeNumber(this.store, this.getCompany.id, this.user.tenant.id);
    },
    async checkDuplicateOrderFunction() {
      this.setOpenOrderConfirm(false);
      const index = this.currentCustomerView.index;
      const isShowDuplicate = ![PAYMENT_METHOD.NETS, PAYMENT_METHOD.STRIPE, PAYMENT_METHOD.PAYNOW].includes(
        this.data.cartItems[index].paymentMethod
      );
      if (isShowDuplicate && this.isSelectDeliveryOverPickup) {
        await this.groupedTenant.map((items, index) => {
          const cartItemIds = items.reduce(
            (ids, item) => (item.available || !item.available ? [...ids, item.id] : ids),
            []
          );
          this.data.cartItems[index].cartItemIds = cartItemIds;
        });
        const itemsFind = [];
        for (let i in this.data.cartItems) {
          itemsFind.push({
            tenantId: this.data.cartItems[i].tenantId,
            deliveryDate: `${this.data.cartItems[i].deliveryDate}`
          });
        }
        this.getCompany = await this.$storage.getSelectedCompany();

        await this[ACTIONS_CART.CHECK_DUPLICATE_ORDER]({
          customerId: this.getCompany.id,
          deliveryAddressId: this.data.deliveryAddressId,
          items: itemsFind
        });
        if (this.status) {
          if (
            this.checkDuplicateOrder.length &&
            this.checkDuplicateOrder[0].payment_type !== 2 &&
            this.checkDuplicateOrder[0].order_status_id === 1
          ) {
            const firstAlert = true;
            this.handleShowContentAlert(firstAlert);
            this.countModalCheckDuplicate = 0;
            setTimeout(() => {
              this.setOpenCheckDuplicatieOrder(true);
            }, 500);
          } else {
            this.addOrder();
          }
        }
      } else {
        this.addOrder();
      }
    },
    async openCheckDuplicatieOrder(params) {
      this.setOpenCheckDuplicatieOrder(false);
      if (params >= this.checkDuplicateOrder.length - 1) {
        this.data.cartItems[params].previousOrderId = this.checkDuplicateOrder[params].id;
        this.messageDuplicate = `${this.$t('order_merged')} #${this.checkDuplicateOrder[0]?.id}`;
        this.addOrder();
      } else {
        const firstAlert = false;
        this.handleShowContentAlert(firstAlert);
        this.data.cartItems[params].previousOrderId = this.checkDuplicateOrder[params].id;
        setTimeout(() => {
          this.setOpenCheckDuplicatieOrder(true);
        }, 500);
      }
    },
    async skipCheckDuplicatieOrder(params) {
      this.setOpenCheckDuplicatieOrder(false);
      if (params >= this.checkDuplicateOrder.length - 1) {
        this.data.cartItems[params].previousOrderId = null;
        this.messageDuplicate = `${this.$t('new_order_placed')}`;
        this.addOrder();
      } else {
        const firstAlert = false;
        this.handleShowContentAlert(firstAlert);
        this.data.cartItems[params].previousOrderId = null;
        setTimeout(() => {
          this.setOpenCheckDuplicatieOrder(true);
        }, 500);
      }
    },
    handleShowContentAlert(firstAlert) {
      this.reRender++;
      this.titleCheckDuplicatieOrder = this.$t('title_check_duplicate');
      this.countModalCheckDuplicate++;
      this.subTitleCheckDuplicatieOrder = `<strong>${
        this.selectedCompany?.name ? this.selectedCompany?.name : this.selectedCompany?.branch
      }</strong> ${this.$t('already_has')}<br/>${this.$t('an_order_with')} <strong>${
        this.checkDuplicateOrder[firstAlert ? 0 : this.countModalCheckDuplicate].tenant_name
      }</strong> ${this.$t('for_delivery_on')}<br/>${this.checkDuplicateOrder[
        firstAlert ? 0 : this.countModalCheckDuplicate
      ].delivery_date.slice(0, 10)} ${
        this.checkDuplicateOrder[firstAlert ? 0 : this.countModalCheckDuplicate].delivery_time
      }.<br/>
      <br/>
      ${this.$t('do_you_want_merge')}`;
    },
    padLeadingZeros(num, size) {
      var s = num.toFixed(2) * 100 + '';
      while (s.length < size) s = '0' + s;
      return s;
    },
    async addOrder() {
      this.groupedTenant.map(async (items, index) => {
        const cartItemIds = items.reduce(
          (ids, item) => (item.available || !item.available ? [...ids, item.id] : ids),
          []
        );
        this.data.cartItems[index].cartItemIds = cartItemIds;

        // remove delivery date from booking order
        if (this.data.isBooking) {
          this.data.cartItems[index].deliveryDate = null;
          this.data.cartItems[index].deliveryTime = null;
        }
      });

      // check NETS payment. NETS payments only can use for the card with 1 tenant
      // process data for NETS card
      // let netsCardError = false;
      if (this.groupedTenant.length === 1) {
        // TODO: Define the behavior when groupedTenant exist
      } else if (
        this.groupedTenant.length > 1 &&
        [PAYMENT_METHOD.NETS, PAYMENT_METHOD.PAYNOW].indexOf(this.data.cartItems[0].paymentMethod) > -1
      ) {
        await this.showAlert({
          header: this.$t('accountPage.nets_card_error_title'),
          message: this.$t('accountPage.nets_card_not_allow')
        });
        return false;
      }
      this.data.isUsingPickupAddress = !this.isSelectDeliveryOverPickup;

      if (this.customerCreditAmount > 0) {
        this.data.appliedCustomerCredit = parseFloat(this.customerCreditAmount);
      }

      this.setOpen(true);
      const addOrderRes = await this[ACTIONS_ORDER.ADD_ORDER](this.data);
      const orderRes = addOrderRes.addOrderB2b;

      if (!this.message) {
        if (
          this.data.cartItems[0].paymentMethod === PAYMENT_METHOD.PAYNOW ||
          orderRes[0].stripeResponse?.url
        ) {
          // close loading
          this.setOpen(false);
          this.$emit('refresh-cart');
          // set checkout session id into storage
          const paymentInfo =
            this.data.cartItems[0].paymentMethod === PAYMENT_METHOD.PAYNOW
              ? orderRes[0]?.paynowRes
              : orderRes[0].stripeResponse;

          // show popup before redirect to Paynow QR code
          this.paymentInfo = paymentInfo;
          if (this.data.cartItems[0].paymentMethod === PAYMENT_METHOD.PAYNOW) {
            this.paymentInfo.order_id = orderRes[0]?.id;
            await this.setOpenPaymentInstruction(true);
          } else {
            this.paymentInfo.order_id = orderRes[0]?.orderId;
            this.onClosePaymentInstruction();
          }

          return;
        }
      }
      this.setOpenOrderConfirm(false);
      this.setOpen(false);
      await this[ACTIONS_CART.FINISH_ORDER](true);
      await this.OrderNotice(orderRes);
    },
    async onClosePaymentInstruction() {
      this.setOpenPaymentInstruction(false);
      if (
        getPlatforms().includes('mobileweb' || 'pwa' || 'desktop') ||
        (!isPlatform('ios') && !isPlatform('android'))
      ) {
        // handle for web
        this.setOpenOrderConfirm(false);
        const threeDSecureData = {
          isRedirect3DSecure: true,
          paymentOrderId: this.paymentInfo?.order_id || this.paymentInfo?.orderId
        };
        await this[ACTIONS_CART.STORE_3D_SECURE_PROCESS](threeDSecureData);
        window.location.href = this.paymentInfo?.url;
      } else if (isPlatform('ios') || isPlatform('android')) {
        // handle for mobile
        await this.$browser.open({
          toolbarColor: '#ffffff',
          presentationStyle: 'fullscreen',
          url: this.paymentInfo?.url
        });

        await this.$browser.addListener('browserFinished', async () => {
          this.setOpenOrderConfirm(false);
          this.setOpen(false);
          this.$emit('place-order');
          this.$router.replace(
            `/b2b/payment-transaction/${this.paymentInfo?.order_id || this.paymentInfo?.orderId}`
          );
          this.$browser.removeAllListeners();
        });
      }
    },
    async OrderNotice(addOrderRes) {
      if (this.message) {
        // check that is stripe payment or not
        this.isStripePaymentError = this.message.includes('Stripe payment error');
        const isAnyUnavailableItems =
          this.message.includes('no longer available') || this.message.includes('is not available');
        const isUnavailableTimeSlot = this.message.includes('time is not available');
        // handle show alert info
        if (this.message === 'Cart item not found!') {
          const alert = await alertController.create({
            header: this.$t('errorTitle'),
            message: this.message,
            buttons: [
              {
                text: this.$t('OK'),
                handler: () => {
                  this.$emit('refresh-cart');
                }
              }
            ]
          });
          alert.present();
        } else if (isUnavailableTimeSlot) {
          await this.showAlert({
            header: this.$t('order_failed'),
            message: this.message
          });
          await this.handleGetDeliveryDate();
          await this.getDefaultDeliveryTime(this.data.cartItems[this.currentDateTime.index]);
          delete this.data.cartItems[this.currentDateTime.index].previousOrderId;
        } else if (isAnyUnavailableItems) {
          this.setOpenItemsNoAvailable(true);
        } else {
          await this.showAlert({
            header: this.$t('order_failed'),
            message: this.message
          });
          this.$emit('refresh-cart');
        }

        // redirect user to cart page only when the failed checkout is not stripe payment
        if (!this.isStripePaymentError && !isAnyUnavailableItems) {
          this.$emit('place-order');
        }
      }

      if (addOrderRes) {
        const orderRes = addOrderRes[0];
        if (orderRes.id) this.orderId = orderRes.id;

        // display blocked message for booking order
        if (orderRes.isBookingOrder && orderRes.blockStatus !== ORDER_BLOCK_STATUS.NOT_BLOCKED) {
          const alert = await alertController.create({
            header: this.$t('orderB2b.unablePlaceBooking'),
            mode: 'ios',
            message: orderRes.blockMessage,
            buttons: orderRes.isBlockedByExceedRemainingCreditLimit
              ? [
                  {
                    text: this.$t('OK'),
                    handler: async () => {
                      this.$emit('close-modal');
                      this.$emit('refresh-cart');
                    }
                  }
                ]
              : [
                  {
                    text: this.$t('close'),
                    handler: async () => {
                      this.$emit('close-modal');
                      this.$emit('refresh-cart');
                    }
                  },
                  {
                    text: this.$t('make_payment'),
                    handler: async () => {
                      this.$emit('close-modal');
                      this.$router.push('/b2b/invoices/select-invoices/');
                    }
                  }
                ]
          });
          await alert.present();
          return;
        }

        // display blocked message for normal order
        if (!orderRes.isBookingOrder && orderRes.blockStatus === ORDER_BLOCK_STATUS.LIMIT_BLOCKED) {
          const alert = await alertController.create({
            header: this.$t('order_on_hold') + ': ' + this.$t('credit_limit_exceeded'),
            mode: 'ios',
            message: orderRes.blockMessage,
            cssClass: 'alert-pending-supplier-acceptance',
            buttons: [
              {
                text: this.$t('make_payment'),
                cssClass: 'alert-button-text-bold',
                handler: async () => {
                  this.$emit('close-modal');
                  this.$router.push('/b2b/invoices/select-invoices/');
                }
              },
              {
                text: this.$t('contact_supplier'),
                cssClass: 'alert-button-text-bold',
                handler: async () => {
                  this.$emit('close-modal');
                  this.$emit('refresh-cart');
                  await this.onClickContactSupplier();
                }
              },
              {
                text: this.$t('later'),
                cssClass: 'alert-button-text-normal',
                handler: async () => {
                  this.$emit('close-modal');
                  this.$emit('refresh-cart');
                }
              }
            ]
          });
          await alert.present();
          return;
        }

        if (orderRes.id) {
          await this.showAlert({
            header: this.$t('success'),
            message: this.messageDuplicate.length > 0 ? this.messageDuplicate : this.$t('order_added')
          });
          await this.handleCheckCuisineCustomer();
          return;
        }
      }
    },

    async handleCheckCuisineCustomer() {
      const { data } = await apolloClient.query({
        query: CheckCustomerCuisines,
        variables: {
          customerId: this.selectedCompany.id
        }
      });
      let isShowModalCuisine = false;
      const cuisineCheck = data.checkCustomerCuisines;
      if (cuisineCheck.isSkip) {
        if (cuisineCheck.totalCountOrders === 1) {
          isShowModalCuisine = true;
        } else if (cuisineCheck.totalCountOrders >= 3 && cuisineCheck.totalCountOrders % 3 === 0) {
          isShowModalCuisine = true;
        }
      }
      if (isShowModalCuisine) {
        this.setOpen(false);
        this.setOpenModalCuisine(true);
      } else {
        await this[ACTIONS_CART.GET_CART_ITEMS]({
          userId: this.user.id,
          customerId: this.selectedCompany.id
        });
        this.$emit('place-order');
      }
    },

    async handleCloasingCuisine() {
      this.setOpenModalCuisine(false);
      await this[ACTIONS_CART.GET_CART_ITEMS]({
        userId: this.user.id,
        customerId: this.selectedCompany.id
      });
      this.$emit('place-order');
    },

    async closeModalAddFavorite() {
      // case yes/no in popup favorite
      this.setOpenAddFavorite(false);
      // check duplicate order
      await this.confirmOrder();
    },

    async onClickContactSupplier() {
      try {
        const { data, errors } = await apolloClient.query({
          query: getSupplierContactInfo,
          variables: {
            customerId: this.selectedCompany.id,
            tenantId: this.user.tenant.id
          }
        });

        if (errors?.length) {
          throw new Error(errors[0].message);
        }

        const contactNumber = data.getSupplierContactInfo?.customerServiceNumber;
        const contactType = data.getSupplierContactInfo?.preferredCommunicationChannel;

        if (!contactNumber) {
          const toast = await toastController.create({
            message: this.$t('contact_your_supplier'),
            duration: 3000,
            color: 'primary',
            position: 'top'
          });
          await toast.present();
          return;
        }

        if (contactType.toLowerCase() === 'whatsapp') {
          const whatsappMessage = this.$t('i_have_question_about_order', {
            orderId: this.orderId
          });
          const whatsappURL = `https://api.whatsapp.com/send?phone=${contactNumber}&text=${encodeURIComponent(
            whatsappMessage
          )}`;

          window.open(whatsappURL, '_blank');
        } else {
          window.location.href = `tel:${contactNumber}`;
        }
      } catch (e) {
        const toast = await toastController.create({
          message: e.message,
          duration: 3000,
          color: 'danger',
          position: 'top'
        });
        await toast.present();
      }
    },

    goToAccountPage() {
      this.$emit('close-modal');
      this.$router.push('/b2b/account/');
    },

    async getXeroCustomer() {
      await this[ACTIONS_PAYMENT.GET_XERO_CUSTOMER]({
        customerId: this.customerView[0]?.id,
        tenantId: this.customerView[0]?.tenant_id
      });
    },

    updateCustomerCreditAmount(value) {
      if (!this.isB2cCustomer || !this.canCheckoutByCustomerCredit) {
        this.customerCreditAmount = 0;
        return;
      }
      if (parseFloat(value) > parseFloat(this.maximumCustomerCreditAmount) || parseFloat(value) < 0) {
        this.customerCreditAmount = this.maximumCustomerCreditAmount;
      } else {
        this.customerCreditAmount = value;
      }

      if (this.customerCreditAmount > 0) this.isCustomerCreditApplied = true;
    },

    applyCustomerCredit(event) {
      if (event.detail.checked) {
        this.customerCreditAmount = this.maximumCustomerCreditAmount;
      } else {
        this.customerCreditAmount = null;
      }
    }
  }
});
</script>
<style lang="scss" scoped>
ion-item::part(native) {
  padding: 0;
}
.payment-type {
  border: 1px solid silver;
  border-radius: 6px;
}
.credit-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;
}

.due-date {
  display: flex;
  color: #fb0101;
  font-weight: bold;
  padding: 5px 42px;
}

.image-card {
  width: 40px;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-center-input {
  text-align: center;
}
</style>

<style lang="scss">
.modal-confirm {
  --width: calc(100% - 5rem);
  --height: fit-content;
  &::part(content) {
    border-radius: 12px;
  }
}
.modal-paynow-info {
  --width: calc(100% - 3rem);
  --height: 220px;
  &::part(content) {
    border-radius: 12px;
  }
}
.adjust-top {
  margin-top: -6px;
}

#row {
  height: 50px;
}

.custom-toggle {
  transform: scale(0.8);
}

@media only screen and (min-width: 600px) {
  .modal-confirm {
    --width: 50%;
    --height: fit-content;
    &::part(content) {
      border-radius: 12px;
    }
  }
}
</style>
